import React from 'react';
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import Button from 'react-bootstrap/Button';

import Layout from '../components/layout';

import ImgArrow from '../img/gr-18th-arrow-text.png';
import ImgGiftButton from '../img/gr-18th-gift-button-150.png';
import ImgMainText from '../img/gr-18th-main-text.png';
import ImgMain from '../img/gr-18th-main.png';
import ImgStreamers from '../img/gr-18th-streamers.png';
import ImgGallery1 from '../img/gr-18th-gallery-1.png';
import ImgGallery2 from '../img/gr-18th-gallery-2.png';
import ImgGallery3 from '../img/gr-18th-gallery-3.png';
import ImgGallery4 from '../img/gr-18th-gallery-4.png';
import ImgGallery5 from '../img/gr-18th-gallery-5.png';
import ImgGallery6 from '../img/gr-18th-gallery-6.png';
import ImgGallery7 from '../img/gr-18th-gallery-7.png';
import ImgGallery8 from '../img/gr-18th-gallery-8.png';
import ImgGallery9 from '../img/gr-18th-gallery-9.png';
import ImgGallery10 from '../img/gr-18th-gallery-10.png';
import ImgGallery11 from '../img/gr-18th-gallery-11.png';
import ImgGallery12 from '../img/gr-18th-gallery-12.png';
import ImgGallery13 from '../img/gr-18th-gallery-13.png';
import ImgGallery14 from '../img/gr-18th-gallery-14.png';
import ImgGallery15 from '../img/gr-18th-gallery-15.png';
import ImgGallery16 from '../img/gr-18th-gallery-16.png';
import ImgGallery17 from '../img/gr-18th-gallery-17.png';
import ImgGallery18 from '../img/gr-18th-gallery-18.png';
import ImgGallery19 from '../img/gr-18th-gallery-19.png';
import ImgGallery20 from '../img/gr-18th-gallery-20.png';
import ImgGiving from '../img/img-sponsor-outreach.svg';
import ImgGift from '../img/img-gift.svg';
import IconTwitter from '../img/icon-purple-twitter.svg';
import IconFacebook from '../img/icon-purple-facebook.svg';
import HelmetComponent from '../components/helmetcomponent';

const pageUrls = require('../constants');

const IMG_GALLERY = [
  ImgGallery1,
  ImgGallery2,
  ImgGallery3,
  ImgGallery4,
  ImgGallery5,
  ImgGallery6,
  ImgGallery7,
  ImgGallery8,
  ImgGallery9,
  ImgGallery10,
  ImgGallery11,
  ImgGallery12,
  ImgGallery13,
  ImgGallery14,
  ImgGallery15,
  ImgGallery16,
  ImgGallery17,
  ImgGallery18,
  ImgGallery19,
  ImgGallery20
];

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block', right: '2%' }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '3%',
        zIndex: '1'
      }}
      onClick={onClick}
    />
  );
}

export default function Template({ data }) {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    centerMode: true,
    variableWidth: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      }
    ]
  };

  const { totalAmount } = data.allContentfulGloryReborn18thAnniversary.edges[0].node;
  const formatAmount = totalAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const percentBar = 100 * (totalAmount / 18000);

  return (
    <Layout nav="colored-nav">
      <HelmetComponent
        title="Celebrating 18 Years"
        description="This August, Glory Reborn turns 18 years old!"
      />
      <div>
        <section className="anniversary-18th-banner-container" id="anniversary-banner">
          <img
            alt="anniversary-streamers"
            className="anniversary-18th-streamers"
            src={ImgStreamers}
          />
          <div className="anniversary-18th-banner">
            <img alt="anniversary-graphic" className="anniversary-18th-main" src={ImgMain} />
            <div className="anniversary-18th-gift-wrapper">
              <img
                alt="anniversary-main-text"
                className="anniversary-18th-text"
                src={ImgMainText}
              />
              <div className="anniversary-18th-gift-container">
                <a className="anniversary-18th-button-hover" data-formid="17893">
                  <img
                    alt="Click here to donate"
                    className="anniversary-18th-gift-button"
                    src={ImgGiftButton}
                  />
                </a>
                <img alt="donate-arrow" className="anniversary-18th-arrow" src={ImgArrow} />
              </div>
            </div>
          </div>
        </section>

        <section className="anniversary-18th-intro mt-5" id="anniversary-intro">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-10 col-sm-12 offset-md-1 text-center">
                <h2 className="mb30 satisfy-font purple">We’re turning 18!</h2>
                <p className="gt-p">
                  This August,{' '}
                  <span className="purple">
                    <strong>Glory Reborn turns 18 years old! </strong>
                  </span>
                  From our humble beginnings in a spare room to what is now a full-fledged birth
                  center, we are so grateful for your unwavering support in fulfilling our mission
                  and vision. As we continue to grow and expand, we aim to touch more lives and
                  build a stronger, brighter future for our moms and babies.
                  <br />
                  <br />
                  This year, help us celebrate our 18th birthday by donating $18 and asking 18
                  friends to do the same. Then join us for a birthday party as we look back on 18
                  years of building Healthy Moms, Healthy Babies, and Hopeful Hearts! To join, make
                  sure to tune in to our Facebook page for a live broadcast on:
                  <br />
                  <br />
                  August 18, 2021 7PM MDT (El Paso, TX) and 6PM PDT (Los Angeles, CA)
                  <br />
                  August 19, 2021 at 9AM (PH Time)
                  <br />
                  <br /> So if you haven't yet, follow us on
                  <a href={pageUrls.GLORY_REBORN_FACEBOOK_URL} className="purple">
                    {' '}
                    Facebook
                  </a>
                  !
                  <br />
                  See you at the party!
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="gallery-container" id="18th-anniversary-gallery">
          <div className="container pt-5">
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-10 offset-md-1 text-center">
                <h2 className="mb20">
                  Here are the moms and babies <br />
                  we've helped for the past 18 years
                </h2>
              </div>
            </div>
          </div>
          <div className="gallery-slider mt-4">
            <Slider {...sliderSettings}>
              {IMG_GALLERY.map(img => {
                return (
                  <div className="gallery-img-container">
                    <img alt="18th anniversary gallery image" className="gallery-img" src={img} />
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>

        <section className="mt130 mb60" id="anniversary-donate">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-6 col-sm-12">
                <img
                  alt="anniversary-sponsor"
                  className="img-gt mt30"
                  src={ImgGiving}
                  width="80%"
                />
              </div>
              <div className="col-md-6 col-sm-12 mt60 text-center">
                <h1>
                  Celebrate <br />
                  <span className="purple">Health</span> & <span className="purple">Hope</span>
                </h1>
                <p className="f20 mb60">Wednesday | August 18, 2021</p>
                <div>
                  <h3 className="mb10 mt20 f22">
                    <span className="bold">${formatAmount}</span> / $18,000
                  </h3>
                  <div className="progress">
                    <div className="progress-bar" style={{ width: `${percentBar}%` }}></div>
                  </div>
                  <Button
                    className="btn-reg btn-slim btn-purple w-40 mt20 mb40"
                    data-formid="17893"
                  >
                    Donate $18
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="anniversary-intro" className="mt130 mb130">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-10 offset-md-1 col-sm-12 text-center">
                <h2 className="mb30">
                  Help us celebrate by <span className="purple">donating $18</span>
                  <br />
                  and sharing to <span className="purple">18 friends!</span>
                </h2>
                <p className="gt-p">
                  This year, help us celebrate our 18th birthday by donating $18 and asking 18
                  friends to do the same. Then on August 18 at 8PM Central, join us for a birthday
                  party as we look back on 18 years of building Healthy Moms, Healthy Babies, and
                  Hopeful Hearts!
                </p>
              </div>

              <div className="row mt80 d-flex w-100 justify-content-center align-items-center">
                <Button
                  href={pageUrls.ANNIVERSARY_SHARE_ON_FACEBOOK_URL}
                  target="_blank"
                  className="btn-white-inverse mb40"
                >
                  <div className="col-sm row align-items-center justify-content-center">
                    <img alt="Share on Facebook" src={IconFacebook} className="mr10" />
                    Share on Facebook
                  </div>
                </Button>
                <Button
                  href={pageUrls.ANNIVERSARY_SHARE_ON_TWITTER_URL}
                  target="_blank"
                  className="btn-white-inverse mb40"
                >
                  <div className="col-sm row align-items-center justify-content-center">
                    <img alt="Tweet on Twitter" src={IconTwitter} className="mr10" />
                    Tweet on Twitter
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section id="sample-gifts" className="mt100 mb100">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <img alt="giving-thanks" className="img-gt" src={ImgGift} />
                <h4 className="mt20 bold">Here are examples of what your gift can provide:</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">$350</h4>
                <p className="gt-p">
                  Safe Delivery Package including labor and delivery services provided by a
                  certified healthcare worker, use of the recovery room, COVID-19 testing
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">$150</h4>
                <p className="gt-p">
                  Well Baby Check-up to make sure baby is healthy upon delivery
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">$35</h4>
                <p className="gt-p">
                  Sponsor A Mom (1 Prenatal Check-up) to ensure mother is healthy during her
                  pregnancy
                </p>
              </div>

              <div className="col-sm-12 col-md-3 text-center mt40">
                <h4 className="purple bold">$25</h4>
                <p className="gt-p">Newborn Hearing Screening</p>
              </div>
            </div>

            <div className="row mt40">
              <div className="col-sm-2 offset-sm-5">
                <Button
                  className="btn-reg btn-slim btn-purple mb20 w-75"
                  data-formid="17893"
                  variant="primary"
                >
                  Give Today
                </Button>
              </div>
            </div>

            <div className="row mt30">
              <div className="col-sm-10 offset-sm-1 bank-details">
                <div className="row text-center">
                  <div className="col-sm-12 col-md-5 offset-md-1 mt30 mb20">
                    <p className="gt-p">
                      <span className="bold gray">
                        Donate from Philippines: <br />
                        <br />
                      </span>
                      <span>
                        <strong>Bank: BPI</strong> <br />
                        Account Number: 2941-0065-18 <br />
                        SWIFT Code: BOPIPHMM <br /> <br />
                        <strong>GCash / Paymaya:</strong> 0917-627-6390
                      </span>
                    </p>
                  </div>
                  <div className="col-sm-12 col-md-5 mt30 mb20">
                    <p className="gt-p">
                      <span className="bold gray">
                        Check Information for USA: <br />
                        <br />
                      </span>
                      <span>
                        <strong>Bank: Wells Fargo</strong> <br />
                        Account Number: 740-174-0951 <br />
                        Routing Number: 121-000-248
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export const anniversary18PageQuery = graphql`
  query anniversary18Page($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
      }
    }
    allContentfulGloryReborn18thAnniversary(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          totalAmount
        }
      }
    }
  }
`;
